import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { State } from '../interface/state';
import { Address } from '../interface/address';
import { City } from '../interface/city';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  /** Used into NF's */
  static readonly BRASIL_CODE = 1058;

  private states: State[];

  constructor(private http: HttpClient) { }

  async getStates(): Promise<State[]> {
    if (this.states) {
      return this.states;
    }
    if (localStorage['states']) {
      return this.states = JSON.parse(localStorage['states']);
    }
    const url = `${environment.mkgoURL}/api/v1/address/states`;
    const response: any = await this.http.get(url).pipe(first()).toPromise();
    this.states = response.states;
    const requests = [];
    this.states.forEach(state => requests.push(this.getCities(state)));
    await Promise.all(requests);
    localStorage['states'] = JSON.stringify(this.states);
    return this.states;
  }

  private async getCities(state: State): Promise<void> {
    const url = `${environment.mkgoURL}/api/v1/address/cities/${state.id}`;
    const response: any = await this.http.get(url).pipe(first()).toPromise();
    state.cities = response.cities;
  }

  async getCity(cityId: string, stateId?: string): Promise<City> {
    if (!this.states || !this.states.length) {
      await this.getStates();
    }
    let city;
    if (stateId) {
      const state = this.states.find(s => s.id === stateId);
      if (state) {
        city = state.cities?.find(c => c?.id === cityId);
        if (city) {
          return city;
        }
      }
    }
    if (this.states.length) {
      const allCities = (this.states.slice().map(st => st.cities)).reduce((s1, s2) => s1.concat(s2), []);
      city = allCities.find(c => c.id === cityId);
    }
    return city;
  }

  async getCityByIBGEcode(code: string): Promise<[City, State]> {
    if (!this.states || !this.states.length) {
      await this.getStates();
    }
    for (const state of this.states) {
      const found = state.cities.find(city => city.igbe === code)
      if (found) {
        return [found, state]
      }
    }
    return null
  }

  getState(stateId: string): State {
    if (!this.states || !this.states.length) {
      this.getStates();
    }
    const state = this.states?.find(state => state?.id === stateId);
    if (!state) {
      this.states = undefined;
      localStorage.removeItem('states');
      this.getStates();
    }
    return state
  }

  async searchCep(cep: string): Promise<Address> {
    const url = `https://viacep.com.br/ws/${cep}/json/`;
    const response: any = await this.http.get(url).pipe(first()).toPromise();
    if (response.erro) {
      throw response;
    }
    let stateId = '';
    let cityId = '';
    for (const state of this.states) {
      if (state.code == (response.uf as string).toUpperCase()) {
        stateId = state.id;
        for (const city of state.cities) {
          if (city.name.toLowerCase() == response.localidade.toLowerCase()) {
            cityId = city.id;
            break;
          }
        }
        break;
      }
    }
    return {
      street: response.logradouro,
      neighborhood: response.bairro,
      state: stateId,
      city: cityId
    }
  }

}
